<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h2 class="my-2 font-semibold text-blue-800 text-2xl">Terminals</h2>
      <div class="flex items-center justify-between">
        <div>
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search Terminal ID or Serial Number or Business name"
            id=""
            v-model="search"
          />
          <button
            title="Search Terminal ID or Serial Number or Business name"
            class="tooltip inline-block uppercase shadow bg-gray-800 hover:bg-gray-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-3 ml-2 rounded"
          >
            <i class="bi bi-info-lg"></i>
            <span class="tooltiptext"
              >Search Terminal ID or Serial Number or Business name</span
            >
          </button>
          <button
            @click="searchBtn"
            id="searchBTN"
            name="searchBtn"
            class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
          >
            Search
          </button>
          <button
            id="clearSearchBTN"
            @click="clearSearchBtn"
            class="inline-block uppercase shadow bg-red-800 hover:bg-red-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
          >
            Clear
          </button>
        </div>
        <div class="my-10 flex justify-end items-end">
          <!-- <button
            @click="
              $router.push({
                name: 'NewTerminal',
              })
            "
            id="newTerminalBTN"
            class="block uppercase shadow bg-blue-600 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
          >
            New Terminal
          </button> -->
          <button
            @click="downloadCsv"
            id="downloadCSVBTN"
            class="block uppercase shadow ml-3 bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
          >
            Download CSv
          </button>
        </div>
      </div>

      <div class="overflow-scroll pb-6" style="height: 600px">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="15"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Phone Number
              </th>

              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Serial Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Bank
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Type
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Provider Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Traction Rep
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Aggregator
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(terminals, i) in filterTerminals"
              :key="i"
              class="hover:bg-gray-300"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.terminal_id }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ terminals.business_name || "N/A" | truncate(30, "...") }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ terminals.business_id || "N/A" | truncate(30, "...") }}
              </td>
              <td
                class="border px-8 py-3 capitalize max-w-sm whitespace-nowrap text-xs"
              >
                {{
                  terminals.assignment.store_name || "N/A" | truncate(30, "...")
                }}
              </td>
              <td
                class="border px-8 py-3 capitalize max-w-sm whitespace-nowrap text-xs"
              >
                {{ terminals.assignment.store_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.business_email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.business_phone || "N/A" }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.serial_no }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.bank_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.physical_address }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.terminal_type }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.terminal_provider_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.traction_rep }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.assignment.is_merchant_aggregator_signup }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="
                    $router.push({
                      name: 'EditTerminal',
                      params: {
                        id: terminals._id,
                      },
                    })
                  "
                  class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                >
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="currentPage === 1 ? 'bg-blue-200' : 'bg-blue-900 text-white '"
          :data-currPage="currentPage"
        >
          &laquo; Prev
        </button>
        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterTerminals.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      isLoading: false,
      search: "",
      moment,
      currentPage: 1,
      isDisabled: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_TERMINALS"]),
    filterTerminals() {
      return this.GET_TERMINALS.data.terminals.filter(
        (terminal) =>
          terminal.business_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          terminal.business_email
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          terminal.terminal_id
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          terminal.serial_no.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(["FETCH_ALL_TERMINALS"]),
    async fetchAllTerminals() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_ALL_TERMINALS");
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async searchBtn() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "SEARCH_ALL_TERMINALS_USING_TERMINAL_ID_OR_SERIAL_NUMBER",
          {
            search: this.search,
          }
        );
        if (res) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      try {
        let res = await this.$store.dispatch("FETCH_PAGINATED_TERMINAL", {
          page: currPage - 1,
        });
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      try {
        let res = await this.$store.dispatch("FETCH_PAGINATED_TERMINAL", {
          page: currPage + 1,
        });
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async downloadCsv() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("DOWNLOAD_ALL_TERMINALS_IN_CSV");
        if (res.status) {
          alert(res.message);
        } else {
          alert(res.message);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    clearSearchBtn() {
      this.search = "";
      this.fetchAllTerminals();
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.fetchAllTerminals();
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
